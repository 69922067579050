<template>
  <qtm-autocomplete
    v-bind="$attrs"
    v-model:search="search"
    clearable
    hide-details
    :item-title="projectNumberName"
    item-value="accounting_id"
    :items="projects"
    :loading="loading"
    no-filter
    placeholder="Search for Project"
    prepend-inner-icon="mdi-magnify"
    return-object
  />
</template>

<script setup lang="ts">
import debounce from 'lodash.debounce'
import type { Company, Jobsite } from '@quotetome/materials-api'

export interface Props {
  company: Company
  filter?: string
}

const props = withDefaults(defineProps<Props>(), {
  filter: 'unimported',
})

const loading = ref(false)
const projects = ref<Jobsite[]>([])
const search = ref('')

const { $api, $error } = useNuxtApp()
const fetchProjects = debounce(async (searchText: string | undefined = undefined) => {
  loading.value = true

  try {
    const response = await $api.v1.accountingIntegrations.listProjects(
      props.company.id as number,
      {
        filter: props.filter,
        search: searchText?.trim(),
        sort: 'name',
      } as any,
    )

    projects.value = response.data
  }
  catch (error) {
    $error.report(error)
  }

  loading.value = false
}, 350)

watch(() => search.value, () => fetchProjects(search.value))

onMounted(() => fetchProjects())

const projectNumberName = (project?: Jobsite) => {
  if (project) {
    return `${project.project_number} - ${project.name}`
  }

  return ''
}
</script>
