<template>
  <qtm-dialog-card
    v-bind="$attrs"
    actions-class="justify-center"
    title="Creating Project"
    width="600"
    @update:model-value="reset"
  >
    <div class="step-wrapper">
      <div v-if="step === 'creationMethod'">
        <div class="mb-3 text-secondary">
          How would you like to create a project?
        </div>
        <div class="qtm-border">
          <div class="bg-white">
            <qtm-radio-group v-model="creationMethod" hide-details>
              <qtm-radio class="my-5 mx-3" value="accounting">
                <template v-slot:label>
                  <div class="text-secondary">
                    <div>
                      <span class="qtm-h3">
                        Import from Accounting
                      </span>
                      <i>
                        (Recommended)
                      </i>
                    </div>
                    <div>
                      Sync project details from an existing project in your accounting software
                    </div>
                  </div>
                </template>
              </qtm-radio>
              <div class="qtm-border-bottom" />
              <qtm-radio class="my-5 mx-3" value="manual">
                <template v-slot:label>
                  <div class="text-secondary">
                    <div class="qtm-h3">
                      Create Static Project
                    </div>
                    <div>
                      Specify project details in QuoteToMe without syncing with your accounting software
                    </div>
                  </div>
                </template>
              </qtm-radio>
            </qtm-radio-group>
          </div>
        </div>
      </div>
      <div v-else-if="step === 'selectCompany'">
        <div class="text-secondary">
          Select the company you'd like to import a project from.
        </div>
        <div>
          <qtm-autocomplete
            v-model="selectedCompany"
            class="mt-3"
            clearable
            hide-details
            item-title="name"
            :items="companies"
            placeholder="Select Company"
            return-object
          />
        </div>
      </div>
      <div v-else>
        <div class="text-secondary">
          Select the project you'd like to pull from your Accounting Software.
        </div>
        <import-project-search v-model="selectedProject" class="mt-3" :company="company" />
      </div>
    </div>

    <template v-slot:actions>
      <div class="my-2">
        <qtm-btn
          v-if="step === 'selectProject'"
          data-test="import-project-btn"
          default-width
          :disabled="!selectedProject"
          size="x-large"
          @click="importProject"
        >
          Import
        </qtm-btn>
        <qtm-btn
          v-else
          default-width
          :disabled="disableNext"
          size="x-large"
          @click="next"
        >
          Next
        </qtm-btn>
      </div>
    </template>
  </qtm-dialog-card>
</template>

<script setup lang="ts">
import useAccountingStore from '@/stores/accounting'
import type { Company, Jobsite } from '@quotetome/materials-api'
import ImportProjectSearch from '@/components/accounting/import-project-search.vue'

export interface Props {
  companies: Company[]
}

const emit = defineEmits(['update:model-value'])

const props = defineProps<Props>()

const creationMethod = ref(undefined)
const selectedCompany = ref<Company | undefined>(undefined)
const selectedProject = ref<Jobsite | undefined>(undefined)
const step = ref('creationMethod')

const accountingStore = useAccountingStore()

const company = computed(() => selectedCompany.value || props.companies[0])
const disableNext = computed(() => {
  if (step.value === 'creationMethod' && !creationMethod.value) {
    return true
  }

  if (step.value === 'selectCompany' && !selectedCompany.value) {
    return true
  }

  return false
})

const router = useRouter()

const importProject = () => {
  if (selectedProject.value) {
    selectedProject.value.id = undefined
    selectedProject.value.timezone = company.value.timezone
    accountingStore.setProjectToCreate(selectedProject.value)
    reset()
    router.push({ name: 'projects-new' })
  }
}

const next = () => {
  if (step.value === 'creationMethod') {
    if (creationMethod.value === 'accounting') {
      if (props.companies.length > 1) {
        step.value = 'selectCompany'
      }
      else {
        step.value = 'selectProject'
      }
    }
    else {
      reset()
      router.push({ name: 'projects-new' })
    }
  }
  else {
    step.value = 'selectProject'
  }
}

const reset = () => {
  creationMethod.value = undefined
  selectedCompany.value = undefined
  selectedProject.value = undefined
  step.value = 'creationMethod'
  emit('update:model-value', false)
}
</script>

<style lang="scss" scoped>
.step-wrapper {
  min-height: 290px;
}
</style>
